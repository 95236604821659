@import "common/variables";
@import "common/mixins";

ul {
    @include SohoGothicPro-Light;
    font-size: $font-size--md;
    line-height: 21px;
    color: $black;
    padding-left: 0;

	@include breakpoint(m) {
        line-height: 26px;
    }

    li {
        padding: 5.25px 0 5.25px 18.75px;

        @include breakpoint(m) {
            padding: 7.5px 0 7.5px 21px;
        }

        list-style: none;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: 3.75px;
            top: 12.75px;

            @include breakpoint(m) {
                top: 16.5px;
            }

            width: 8.25px;
            height: 7.5px;
            background-image: url(../assets/ul.svg);
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

ol {
    @include SohoGothicPro-Light;
    font-size: $font-size--md;
    line-height: 21px;
    counter-reset: nederma-counter;
    color: $black;
    padding-left: 0;

    @include breakpoint(m) {
        line-height: 26px;
    }

    li {
        counter-increment: nederma-counter;
        min-height: 30.75px;
        padding: 5.25px 0 12.75px 36.75px;
        position: relative;
        list-style: none;

        @include breakpoint(m) {
            padding: 6.75px 0 17.25px 40.5px;
        }

        &:before {
            font-size: $font-size--xs;
            line-height: 21px;
            @include SohoPro-Light;
            color: $white;
            background-color: $dark-green;
            border-radius: 100%;
            text-align: center;
            content: counter(nederma-counter);
            position: absolute;
            left: 0;
            top: 3px;
            width: 27px;
            height: 27px;
            padding: 3.75px 0;

            @include breakpoint(m) {
                font-size: $font-size--lg;
                line-height: 26px;
                padding: 1.25px 0;
                width: 30.75px;
                height: 30.75px;
            }
        }

        &.breadcrumb-item {
            padding: 0;

            &:before {
                display: none;
            }
        }
    }
}