﻿@font-face {
    font-family: 'SohoGothicPro-Light';
    src: url('../../../../../ui/fonts/webconfigurator/SohoGothicPro-Light.otf') format('opentype'), url('../../../../../../https://localhost:44304/ui/fonts/webconfigurator/SohoGothicPro-Light.ttf') format('truetype'), url('../../../../../../https://localhost:44304/ui/fonts/webconfigurator/SohoGothicPro-Light.ttf') format('truetype'), url('../../../../../../https://localhost:44304/ui/fonts/webconfigurator/SohoGothicPro-Light.woff') format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'SohoPro-Light';
    src: url('/ui/fonts/webconfigurator/SohoPro-Light.otf') format('opentype'), url('../../../../../../ui/fonts/webconfigurator/SohoPro-Light.ttf') format('truetype'),url('../../../../../../https://localhost:44304/ui/fonts/webconfigurator/SohoPro-Light.woff') format('woff');
    font-display: swap;
}

@mixin SohoGothicPro-Light() {
    font-family: "SohoGothicPro-Light", serif;
    font-style: normal;
    font-weight: 300;
}

@mixin SohoPro-Light() {
    font-family: "SohoPro-Light", serif;
    font-style: normal;
    font-weight: 300;
}


@mixin breakpoint($point) {
    @if $point == xxl {
        @media (min-width: 1400px) {
            @content;
        }
    }

    @if $point == xl {
        @media (min-width: 1200px) {
            @content;
        }
    }

    @if $point == l {
        @media (min-width: 992px) {
            @content;
        }
    }

    @else if $point == m {
        @media (min-width: 768px) {
            @content;
        }
    }

    @else if $point == s {
        @media (min-width: 576px) {
            @content;
        }
    }

    @else if $point == xs {
        @media (min-width: 576px) {
            @content;
        }
    }


    @else if $point == ie-fix {
        /*ie 10+ fixes*/
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            @content;
        }
    }
}
