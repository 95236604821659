@import "common/variables";
@import "common/mixins";
@import "partials/breadcrumb";
@import "partials/button";
@import "partials/card";
@import "partials/footer";
@import "partials/headings";
@import "partials/hero";
@import "partials/knowledge";
@import "partials/lists";
@import "partials/menu";
@import "partials/news";
@import "partials/spot";
@import "partials/textAndImage";
@import "partials/twoColumn";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
}

body {
    overflow-x: hidden;
}

.container {
    max-width: 1179px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;


    &-sm {
        max-width: 975px;
    }

    &-fluid {
        &-bg {
            position: relative;
            margin-top: 76px;
            opacity: 1;
            background: $green;
            overflow-y: visible;
            max-width: 1500px;
            border-top: 1px solid $green;

            @include breakpoint(m) {
                margin-top: 181px;
                padding-top: 90px;
            }
            /*EJ omräknad*/
            &:before {
                content: "";
                position: absolute;
                top: -150px;
                left: 0;
                width: 100%;
                height: 150px;
                background: $green;
                clip-path: polygon(0 100%, 100% 100%, 0 50%);
                border-bottom: 1px solid $green;

                @include breakpoint(m) {
                    height: 250px;
                    top: -250px;
                }
            }
            /*EJ omräknad end*/
            @include breakpoint(m) {
                .container-sm {
                    padding-top: 0 !important;
                }
            }
        }
    }
}

.preamble {
    @include SohoGothicPro-Light;
    font-size: $font-size--xl;
    line-height: 30px;
    color: $light-black;
}

a {
    color: $dark-blue;

    &:hover {
        color: $dark-blue;
    }
}

input[type=submit] {
    font-size: $font-size--xs;
    line-height: 18px;
    padding: 8.25px 15px;
    border-radius: 4px;
    border: 0.75px solid $dark-blue;
    color: $dark-blue;
    position: relative;
    background-color: transparent;

    &:hover {
        background-color: $dark-blue;
        color: $white;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.nederman-logo {
    max-width: 207px;
    margin-top: 30px;
    margin-bottom: 7.5px;
    display: inline-block;
}

.segmentNews {
    margin-bottom: 108px;

    .row {
        margin-left: -27.75px;
        margin-right: -27.75px;
    }

    &-item {
        padding: 0 18.75px;
    }

    &-text {
        font-size: $font-size--lg;
        line-height: 30px;
        @include SohoPro-Light;
        color: $light-black;
        padding-top: 18px;
        margin-bottom: 12px;
    }

    &-link {
        text-decoration: none;
        color: $light-black;
    }

    p {
        font-size: $font-size--md;
        line-height: 28px;
        letter-spacing: 0.38px;
    }

    .btn {
        margin-top: 36px;
    }
}

.subItemsList {
    &-item {
        padding: 18.75px;
    }

    &-text {
        font-size: $font-size--md;
        line-height: 28px;
        @include SohoPro-Light;
        color: $light-black;
        padding-top: 18px;
        margin-bottom: 12px;
    }

    img {
        margin-bottom: 18px;
    }
}

.form-group {
    margin-bottom: 15px;
}

.bold, b, strong {
    font-weight: 700;
}

.hide-numbers:before {
    content: none;
}

.sub-page {
    max-width: 1307px;
	
    h1, .h1 {
		@include breakpoint(m) {
			margin-top: 21px;
        }
		
        &, & a {
			color: $dark-green;

            @include breakpoint(m) {
                font-size: 44px;
                line-height: 50px;
            }
        }
    }
}

.video-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%; 
    }
}
