﻿@import "../common/_variables.scss";
@import "../common/_mixins.scss";

.Hero {
    margin-bottom: 40.5px;
    background-size: cover;

    @include breakpoint(m) {
        margin-bottom: 44.25px;
    }

    a {
        text-decoration: none;
    }

    &-container {
        padding-top: 18.75px;
        padding-bottom: 17.75px;

        @include breakpoint(m) {
            height: 412.5px;
        }

        &-small {
            @include breakpoint(m) {
                height: 341.25px;
            }
        }

        .row {
            height: 100%;
            max-width: 100%;
            margin: auto;
        }
    }

    &-content {
        background-color: rgba(255,255,255,.74);
        padding: 26.25px 18px;

        @include breakpoint(m) {
            padding: 52.5px 40.5px 41.25px;
        }
    }

    &-header {
        @include SohoPro-Light;

        @include breakpoint(m) {
            font-size: $font-size--xxxxl;
            line-height: 51.75px;
            margin-bottom: 18px;
        }

        font-size: $font-size--xxxl;
        line-height: 39.75px;
        color: $dark-green;
        margin-bottom: 11.25px;
    }

    &-text {
        margin-bottom: 0;
        @include SohoGothicPro-Light;
        color: $light-black;
    }
}
