@import '../common/_variables.scss';
@import '../common/_mixins.scss';

.knowledge-center {
	font-family: 'SohoGothicPro-Light', serif;
}

.knowledge-center__article {
	width: 100%;
	border-top: 1px solid $gray;
}

.knowledge-center__article:last-of-type {
	padding-bottom: 16px;
	border-bottom: 1px solid $gray;
}

.knowledge-center__link {
	text-decoration: none;
}

.knowledge-center h2 {
	color: $dark-green;
}

.knowledge-center__subheading {
	color: $dark-blue;
	margin-top: 0;
}

.knowledge__image {
	display: block;
	max-width: 100%;
	height: auto;
}

.knowledge-center__input-radio {
	display: none;
	cursor: pointer;
}

.knowledge-center__input-radio-button {
	display: inline-block;
	width: 20px;
	height: 20px;
	margin: -1px 4px 0 0;
	vertical-align: middle;
	cursor: pointer;
	-moz-border-radius: 50%;
	border-radius: 50%;
	position: relative;
	border: 1px solid $gray;
}

.knowledge-center__label {
	font-family: 'SohoGothicPro-Light', serif;
	color: $black;
	font-size: $font-size--sm;
	cursor: pointer;
}

.knowledge-center__label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
}

.knowledge-center__input-radio:checked + .knowledge-center__label {
	color: $dark-blue;
	pointer-events: none;
}

.knowledge-center__input-radio:checked + .knowledge-center__label .knowledge-center__input-radio-button:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: 10px;
	background-color: $dark-blue;
	border-radius: 50%;
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.knowledge__pagination {
	text-align: center;
}

.knowledge__pagination-link {
	font-family: 'SohoGothicPro-Light', serif;
	text-decoration: none;
}

.knowledge__pagination-link--disabled {
	opacity: 0.3;
}