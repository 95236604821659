@import "../common/_variables.scss";
@import "../common/_mixins.scss";

.two-one--column-grid, two-one--column-grid, .TwoColumnWidth {
    .TwoColumnWidth .container-sm {
        padding: 0;
    }

    h1, .h1 {
        &, & a {
			color: $dark-green;

            @include breakpoint(m) {
                font-size: 44px;
                line-height: 50px;
            }
        }
    }

    h2, .h2 {
        &, & a {
            @include breakpoint(m) {
                line-height: 30px;
                font-size: 30px;
                margin-bottom: 26px;
            }
        }
    }

    h3, .h3 {
        &, & a {
            @include breakpoint(m) {
                line-height: 28px;
                font-size: $font-size--xxl;
                margin-bottom: 20px;
            }
        }
    }

    h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
        color: #434343;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
        @include breakpoint(m) {
            margin-top: 52px;
        }
    }

    p {
        &, & a {
            @include breakpoint(m) {
                margin-bottom: 24px;
            }

            color: #434343;
        }
    }

    &.container-fluid {
        &-bg {
            max-width: 100%;
        }
    }

    .container {

        &-sm {
            max-width: 1307px;

            .article {
                max-width: calc(738px + (var(--bs-gutter-x) * .5) * 2);
            }

            .right {
                max-width: calc(353px + (var(--bs-gutter-x) * .5) * 2);

                img {
                    margin-bottom: 0;
                }

                h2, .h2 {
                    &, & a {
                        font-size: calc(1.3rem + .6vw);
                        margin-top: 18px;

                        @include breakpoint(m) {
                            line-height: 28px;
                            font-size: $font-size--xxl;
                            margin-bottom: 7px;
                        }
                    }
                }

                p {
                    @include breakpoint(m) {
                        line-height: 23px;
                        font-size: $font-size--sm;
                    }
                }

                .col-md-4 {
                    width: 100% !important;
                }

                .col-12 {
                    margin-bottom: 32px !important;

                    @include breakpoint(m) {
                        margin-bottom: 57px !important;
                    }
                }
            }
        }

		&-columns {
			margin-top: 80px;
			margin-bottom: 80px;
		}
    }
}