﻿@import "../common/_variables.scss";
@import "../common/_mixins.scss";

.footer-navigation {
    padding-top: 15px;
    padding-bottom: 15px;
    /*margin-top: 10px;*/

    @include breakpoint(m) {
        padding-top: 72px;
        padding-bottom: 27.75px;
        margin-top: 33.75px;
    }

    a {
        color: $white;
        text-decoration: none;


        &:hover {
            text-decoration: underline;
            opacity: 1;
        }
    }

    ul {
        margin-bottom: 30px;

        @include breakpoint(m) {
            margin-bottom: 84px;
        }

        li {
            padding: 0;

            &:before {
                display: none;
            }

            a {
                font-size: $font-size--xxs;
                line-height: 28.5px;
                opacity: 0.7;

                @include breakpoint(m) {
                    font-size: $font-size--xs;
                    line-height: 30.75px;
                }
            }
        }
    }

    h3 a {
        @include breakpoint(m) {
            line-height: 31.5px;
            font-size: $font-size--xl;
            margin-bottom: 26.25px;
            margin-top: 0;
        }
    }

    background-color: $light-black;
    color: $white;

    &-bottom {
        border-top: $border-width--xs solid $white;
    }

    .contact {
        width: 24.75px;
        height: auto;
        margin-right: 8.25px;
    }

    .social-media {
        height: 19.5px;
        width: auto;
        margin-right: 16.5px;
    }
}
