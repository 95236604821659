﻿@import "../common/_variables.scss";
@import "../common/_mixins.scss";

header {
    .container {
        overflow: inherit;
    }
}

.u-limitPageWidth {
    padding: 39.75px 0 37.5px;
}

.navbar > .container {
    padding-left: var(--zero);
    padding-right: var(--zero);
}

.service-menu {
    background-color: $light-green;

    .container {
        overflow: visible;
    }

    nav {
        padding-top: var(--zero);
        padding-bottom: var(--zero);
    }

    li {
        padding-left: 7.5px;

        @include breakpoint(m) {
            padding: 15px 11.25px;
        }

        a {
            padding: 0 !important;

            @include breakpoint(m) {
                font-size: $font-size--xs;
                line-height: 15px;
            }

            @include SohoPro-Light;
            color: $light-black;
            opacity: 0.74;
            letter-spacing: 0.38px;
            text-decoration: none;

            &:after {
                background-image: url(../assets/chevron-right.svg);
                width: 6.75px;
                height: 4.5px;
                border: none;
                vertical-align: middle;
                background-repeat: no-repeat;
                position: absolute;
                top: 9.75px;
                right: 7.5px;
                background-size: 6.75px;
                background-position: center;

                @include breakpoint(m) {
                    position: static;
                    top: auto;
                    right: auto;
                }
            }

            &:hover, &:active, &:focus, &.show {
                color: $light-black;
            }

            &.show {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }

        li {
            a {
                display: block;
                margin-bottom: 15px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        @include breakpoint(m) {
            &:hover {
                background-color: white;
            }
        }

        &:before {
            display: none;
        }
    }

    &-dropdown {
        @include breakpoint(m) {
            padding: 30px;
            right: 0;
            left: auto !important;
            background-color: white !important;
            box-shadow: var(--zero) 1.5px 7.5px rgba(0,0,0,0.188235);
        }
        /*Ej minskat*/
        padding-right: calc(1.5rem * 0.5);
        padding-left: calc(1.5rem * 0.5);
        background-color: transparent !important;
        border-radius: $border-radius--none;
        margin-top: 0 !important;
        border: none;
        /*Ej minskat end*/
        .dropdown-item {
            @include breakpoint(m) {
                font-size: $font-size--xs;
                line-height: 30.75px;
                letter-spacing: 0.26px;
            }

            opacity: 0.7;
            color: $light-black;
            font-size: $font-size--xs;
            line-height: 30px;

            &.active {
                background-color: transparent;
                color: $light-black;
            }

            &:hover {
                text-decoration: underline;
                background-color: transparent;
            }
        }
    }
}

.main-navigation {
    margin-bottom: 25.5px;

    .align-items-center {
        margin-bottom: 0 !important;
    }

    li {
        padding: 0 7.5px;

        &:after {
            background-image: url(../assets/chevron-right.svg);
            width: 6.75px;
            height: 4.5px;
            border: none;
            vertical-align: middle;
            background-repeat: no-repeat;
            position: absolute;
            top: 9.75px;
            right: 7.5px;
            padding: 11.25px;
            background-size: 6.75px;
            background-position: center;
        }

        &.show {
            &:after {
                transform: rotate(180deg);
            }
        }

        @include breakpoint(m) {
            margin-right: 15px;
            position: unset;
            padding-top: 7.5px;
            padding-bottom: 7.5px;

            &:after {
                display: none;
            }
        }

        &:before {
            display: none;
        }

        a {
            @include SohoPro-Light;
            color: $light-black;
            opacity: 0.84;
            width: 100%;
            position: relative;
			white-space: normal;
			
            @include breakpoint(m) {
                font-size: $font-size--md;
                line-height: 18.75px;
                margin-right: 0;
                position: unset;
                width: 100%;

                &:after {
                    display: none;
                }

                &:hover {
                    color: $light-black;
                    background-color: transparent;
                    text-decoration: underline;
                }
            }
        }

        @include breakpoint(m) {
            &:hover, &.show {
                background-color: $light-green;
            }
        }
    }

    .header-dropdown, .products-navigation {
        @include breakpoint(m) {
            position: absolute;
            top: 181px;
            width: 100%;
            /*min-height: 414px;*/
            padding: 30.75px 0;
            background-color: $light-green;
        }

        border: none;
        border-radius: $border-radius--none;
        left: 0;

        .container {
            max-width: 892.5px;
        }

        a {
            color: $dark-green;
            line-height: 18.75px;
            font-size: $font-size--xs;

            @include breakpoint(m) {
                font-size: $font-size--sm;
                margin: 18.75px 0;
            }

            padding: 0;
            text-decoration: none;
        }

        h3 {
            margin-bottom: 3.75px;

            @include breakpoint(m) {
                margin-bottom: 15px;
            }

            a {
                @include SohoPro-Light;

                @include breakpoint(m) {
                    font-size: $font-size--md;
                }

                font-size: $font-size--xs;
                line-height: 22.5px;
                color: $dark-green;
            }
        }
    }

    .products-navigation {
        padding-top: 6px;

        ul {
            padding: 0;
            margin-bottom: 3.75px;

            @include breakpoint(m) {
                margin-bottom: 21.75px;
            }

            li {
                padding-left: 0;
                margin: 0;

                &:before {
                    display: none;
                }

                a {
                    @include SohoGothicPro-Light;

                    @include breakpoint(m) {
                        font-size: $font-size--xs;
                    }

                    line-height: 28px;
                    color: $light-black;
                }
            }
        }

        &-item {
            padding: 0;


            @include breakpoint(m) {
                padding: 36.75px 0 0;
                height: 100%;
                white-space: normal;
            }

            .p-2 {
                margin: 0;
                padding: 0 !important;
                white-space: normal;

                @include breakpoint(m) {
                    white-space: normal;
                }
            }

            .border {
                margin-top: 15px;
                margin-bottom: 15px;

                @include breakpoint(m) {
                    margin-top: 36.75px;
                    margin-bottom: unset;
                }

                max-width: 228px;
                height: .75px;
                background: $dark-green;
                opacity: 0.34;
                width: 100%;
            }
        }
    }

	.search-container {
		display: flex;
		align-items: center;
		width: 100%;
	}

    &-search {
		position: relative;
		display: inline-block;
		border: .75px solid $green;
		border-radius: $border-radius--sm;
		opacity: 1;
		box-shadow: var(--zero) 2.25px 4.5px #0000000d;
		
		@include breakpoint(m) {
			border: none;
			border-radius: $border-radius--sm;
			opacity: 1;
			box-shadow: none;
			font-size: $font-size--md;
			line-height: 18.75px;

			& input::placeholder{
				color: transparent;
			}

			&--active {
				border: .75px solid $green;
				box-shadow: var(--zero) 2.25px 4.5px #0000000d;
				
				& .form-control::placeholder{
					color: $light-black;
				}
			}
		}
		
        input {
            border: none;
            box-shadow: none;
            @include SohoPro-Light;

            @include breakpoint(m) {
                font-size: $font-size--md;
                line-height: 18.75px;
            }

            &:focus, &:active, &:visited {
                outline: none;
                box-shadow: none;
            }
        }

        #search-result {
            position: absolute;
            z-index: 10000000000000000000000000000000000000000;
            height: auto;
            width: 100%;
            background-color: #fff;
            top: 31px;
            left: 0;
            box-shadow: 0 2px 10px #00000030;
            padding: 8px 18px 18px;

            .results {
                border-bottom: 1px solid #e4eded;
                padding-bottom: 10px;

                li {
                    padding: 4.5px 0;
                    margin: 0;

                    a {
                        font-size: $font-size--sm;
                        line-height: 36px;
                        text-decoration: none;
                        position: relative;
                        padding-right: 0;
                        display: block;

                        &:hover {
                            text-decoration: underline;
                        }

                        .icon {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 0;
                            height: 6.75px;
                            width: auto;
                        }
                    }
                }
            }

            .btn {
                padding: 0;
                border: none;
                color: $dark-blue;
                font-size: $font-size--xs;
                line-height: 18px;

                img {
                    height: 6.75px;
                    width: auto;
                }
            }
        }
    }

    img {
        width: 31.5px;
        height: 31.5px;
        cursor: pointer;
    }

    .mobile-button-toggle {
        width: 15px;
    }
}

.search-button {
	background: none;
	min-height: 31.5px;
	max-width: 31.5px;
	padding-left: 8px;
}

.search-button-icon {
	min-height: 31.5px;
	max-width: 31.5px;
}

.products-navigation {
    &-list {
        li {
            &:before {
                display: none;
            }
        }
    }
}

.dropdown-menu-group {
    border: none;
    background-color: transparent !important;

    @include breakpoint(m) {
        width: 495px;
        box-shadow: var(--zero) 1.5px 7.5px #00000030;
        border-radius: $border-radius--none;
        margin-top: 0 !important;
        background-color: white !important;

        .nav-item {
            padding: 30px 25.5px 15px;

            img {
                width: 17.25px;
                height: 17.25px;
                margin-right: 2px;
                margin-top: -3px;
            }
        }
    }

    .dropdown-menu-title {
        @include SohoPro-Light;
        color: $dark-green;
        font-size: $font-size--sm;
        line-height: 16.5px;

        @include breakpoint(m) {
            font-size: $font-size--md;
            line-height: 18px;
        }

        margin-bottom: 19.5px;
    }

    hr {
        margin-top: 12px;
        margin-bottom: 25.5px;
        background-color: $dark-green;
    }
}

.logo {
    max-width: 207px;
    max-height: 30px;
    margin-bottom: 16.5px;
    margin-left: 15px;
}
