﻿@import "../common/_variables.scss";
@import "../common/_mixins.scss";

.textAndImage {
    /*max-width: 100%;*/
    overflow: hidden;
    margin: 22.5px auto;
    max-width: 1500px;

    @include breakpoint(m) {
        margin: 81px auto;
    }

    &-text {
        z-index: 1;

        &-left, &-right {
            padding-top: 20px;

            @include breakpoint(m) {
                padding-top: 37.5px;
                padding-bottom: 37.5px;
            }

            @include breakpoint(l) {
                max-width: 536.25px;
                padding-top: 75px;
                padding-bottom: 75px;
            }
        }

        .btn {
            margin: 9px 15px 9px 0;
        }

        p {
            margin-bottom: 20.25px;
        }

        h2, h2 a, .h2, .h2 a {
            @include breakpoint(m) {
                line-height: 31.5px;
                font-size: $font-size--xxl;
                margin-bottom: 19.5px;
            }
        }

        h3 {
            margin-bottom: 15.75px;

            @include breakpoint(m) {
                line-height: 22.5px;
                font-size: $font-size--md;
            }
        }
    }

    &-image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        min-height: 270px;

        @include breakpoint(s) {
            min-height: 350px;
        }

        @include breakpoint(l) {
            min-height: auto;

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 105%;
                background-color: $white;
                top: 50%;
                transform: skewX(-13deg) translateY(-50%);
            }

            &-right:before {
                transform: skewX(-13deg) translateY(-50%);
                right: 95%;
            }

            &-left:before {
                transform: skewX(13deg) translateY(-50%);
                left: 95%;
            }
        }

        @media(min-width: 1200px) {

            &-right:before {
                right: 94%;
            }

            &-left:before {
                left: 94%;
            }
        }

        @media(min-width: 1360px) {

            &-right:before {
                right: 92%;
            }

            &-left:before {
                left: 92%;
            }
        }
    }
}
