﻿@import "../common/_variables.scss";
@import "../common/_mixins.scss";

.card {
    background-color: transparent;
    background-clip: border-box;
    border: none;
    border-radius: unset;

    &Item {
     	margin-bottom: 20px;

		 @include breakpoint(m) {
            padding: 0 32.25px;
        }

        img {
            width: 100%;
            margin-bottom: 18px;
        }

        &-link {
            text-decoration: none;
            color: $light-black;

            h2 {
                @include breakpoint(m) {
                    line-height: 31.5px;
                    font-size: $font-size--xl;
                    margin-bottom: 19.5px;

                    &.h4 {
                        line-height: 30px;
                        font-size: $font-size--lg;
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }

    &-title {
        color: $light-black;
        opacity: .84;


        @include breakpoint(m) {
            line-height: 30px;
            font-size: $font-size--lg;
            margin-bottom: 12px;
        }
    }

    &-text {
        opacity: .84;
    }

    &-icon {
        width: 6px !important;
        height: 9px;
        vertical-align: middle;
        margin: 0 3.75px !important;
    }
}

.two-one--column-grid {
    .card {
        background-color: transparent;
        background-clip: border-box;
        border: none;
        border-radius: unset;

        &Item {
            @include breakpoint(m) {
                padding: 0 0 32.25px;
            }
        }
    }
}
