@import "../common/_variables.scss";
@import "../common/_mixins.scss";
h1, .h1 {
    &, & a {
        @include SohoPro-Light;
        color: $dark-green;
        line-height: 27px;
        font-size: $font-size--xxl;

        @include breakpoint(m) {
            font-size: 36px;
            line-height: 44px;
        }
    }
}

h2, .h2 {
    &, & a {
        @include SohoPro-Light;

        margin-bottom: 12px;


        @include breakpoint(m) {
            line-height: 30px;
            font-size: 30px;
            margin-bottom: 26px;
        }
    }
}

h3, .h3 {
    &, & a {
        @include SohoPro-Light;
        margin-bottom: 12px;


        @include breakpoint(m) {
            line-height: 28px;
            font-size: $font-size--xxl;
            margin-bottom: 20px;
        }
    }
}

h4, .h4 {
    &, & a {
        @include SohoPro-Light;
        margin-bottom: 12px;

        @include breakpoint(m) {
            line-height: 30px;
            font-size: $font-size--xl;
        }
    }
}

p {
    &, & a {
        @include SohoGothicPro-Light;
        color: $light-black;
        font-size: $font-size--md;
        line-height: 28px;
    }
	@include breakpoint(m) {
		margin-bottom: 20px;
	}
}

h2, .h2, h3, .h3, h4, .h4, h5, .h5, p {
    &, & a {
        color: $light-black;
    }
}