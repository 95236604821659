﻿@import "../common/_variables.scss";
@import "../common/_mixins.scss";

.spot {
    &NoBackground, &FullBackground {
        margin: 22.5px 0 0;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(m) {
            margin: 78.75px 0 0;
        }


        &-text {
            p {
                margin-bottom: 29.25px;
            }
        }
    }

    &NoBackground {
        &-text {
            max-width: 681px;

            h2 {
                margin-bottom: 23.25px;
            }
        }
    }

    &FullBackground, &FullBackgroundAndImage {
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 18.75px;
        padding-bottom: 18.75px;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;


        @include breakpoint(m) {
            padding-top: 67.5px;
            padding-bottom: 67.5px;
            margin-bottom: 56px;
        }

        &-text {
            padding: 26.25px 18px;

            @include breakpoint(m) {
                padding: 30px;
            }

            @include breakpoint(l) {
                padding: 57px 67.5px;
            }

            background-color: rgb(255 255 255 / 74%);
        }

        .container {
            max-width: 1309.5px;

            &-sm {
                .spotFullBackground-text {

                    @include breakpoint(m) {
                        h2, h2 a, .h2, .h2 a {
                            line-height: 31.5px;
                            font-size: $font-size--xxl;
                            margin-bottom: 19.5px;
                        }
                    }

                    @include breakpoint(l) {
                        padding: 57px 167.25px;
                    }
                }
            }
        }
    }

    &FullBackgroundAndImage {
        .container {
            &-sm {
                .spotFullBackground-text {
                    @include breakpoint(l) {
                        padding: 57px 92.25px;
                    }

                    img {
                        /*box-shadow: 0px 3px 15px #00000059;*/
                        width: 164.25px;
                        max-width: 100%;
                        margin-top: 26.25px;

                        @include breakpoint(m) {
                            margin-top: 0px;
                        }
                    }
                }
            }
        }
    }
}
