@import 'common/variables';
@import 'common/mixins';

.btn {
	@include SohoGothicPro-Light;
	font-size: $font-size--sm;
	line-height: 18px;
	padding: 8.25px 32.25px 8.25px 15px;
	border-radius: $border-radius--sm;
	border: $border-width--xs solid $dark-blue;
	position: relative;
	background-color: transparent;

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translateY(-50%);
		width: 4.5px;
		height: 6.75px;
		background-repeat: no-repeat;
		background-size: contain;
	}

	&:hover {
		background-color: $dark-blue;
		color: $white;
	}

	&-primary,
	&-default {
		border-color: $dark-blue;
		color: $dark-blue;

		&:after {
			background-image: url('../assets/chevron-right-solid-blue.svg');
		}

		&:hover {
			background-color: $dark-blue;
			color: $white;

			&:after {
				background-image: url('../assets/chevron-right-solid-white.svg');
			}
		}
	}

	&-success,
	&-secondary {
		border-color: $dark-green;
		color: $dark-green;

		&:after {
			background-image: url('../assets/chevron-right-solid-green.svg');
		}

		&:hover {
			background-color: $dark-green;
			color: $white;

			&:after {
				background-image: url('../assets/chevron-right-solid-white.svg');
			}
		}
	}
}
