@import "common/variables";
@import "common/mixins";

.breadcrumb {
    &s {
        margin-top: 11.25px;
        margin-bottom: 21px;

        &-nav {
            margin-top: 23.25px;
            margin-bottom: 21px;

            a {
                margin-right: 15px;
                margin-bottom: 15px;
            }
        }
    }

    &-item {
        @include SohoGothicPro-Light;
        font-size: $font-size--xs;
        line-height: 18px;
        letter-spacing: 0.24px;
        color: $dark-green;
        position: relative;
        padding-right: 12.75px;
        text-decoration: none;

        &:before {
            display: none;
        }

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 6.75px;
            width: 4.5px;
            height: 6.75px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('../assets/chevron-right-solid-green.svg');
        }

		&:hover {
			color: $dark-green;
			text-decoration: underline;
		}

        &-current {
            color: $light-black;

			&:hover {
				color: initial;
				text-decoration: initial;
			}

            &:after {
                display: none;
            }
        }
    }
}
