﻿//colors
$white: #ffffff;
$blue: #009ee3;
$light-black: #434343;
$black: #383838;
$dark-blue: #0062af;
$dark-green: #2e7773;
$green: #e4eded;
$gray: #00000029;
$gray: #c7c7c7;
$dark-gray: #b8b8b8;
$light-green: #f2f7f7;

:root {
    --zero: 0;
}

//border
$border-width--xs: 0.75px;
$border-width--sm: 1px;
$border-width--md: 2px;

//border radius
$border-radius--none: 0;
$border-radius--sm: 3px;

//font-sizes
$font-size--huge:	50px;
$font-size--xxxxl:	40px;
$font-size--xxxl:	32px;
$font-size--xxl:	24px;
$font-size--xl:		20px;
$font-size--lg:		18px;
$font-size--md:		16px;
$font-size--sm:		14px;
$font-size--xs:		12px;
$font-size--xxs:	10px;
