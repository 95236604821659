﻿@import "../common/_variables.scss";
@import "../common/_mixins.scss";

.news {
    padding: 11.25px 0 21.75px;
    margin-bottom: 40.5px;

    @include breakpoint(m) {
        padding: 83.25px 0 53.25px;
        margin-bottom: 53.25px;
    }

    position: relative;

    h2 {
        @include breakpoint(m) {
            line-height: 38px;
            font-size: 30px;
        }

        color: $light-black;
        margin-bottom: 26.25px;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        background-color: $green;
        height: 428.25px;
        z-index: -1;
        max-width: 1500px;
        transform: translateX(-50%);
    }

    .margin-top {
        @include breakpoint(m) {
            padding-top: 12px;
        }

        img {
            margin-top: 0;
            width: 100%;

            @include breakpoint(l) {
                margin-top: -60.25px;
            }
        }
    }

    @include breakpoint(l) {
        .btn-success {
            margin-right: 24.75px;
        }
    }

    &-item {
        padding: 1.5rem var(--zero);

        @include breakpoint(m) {
            padding: 24.75px var(--zero);
        }

        @include breakpoint(l) {
            padding: 65px 24.75px;

            &-img {
                padding: var(--zero) 24.75px;
            }

            &-auto {
                width: auto;
                display: inline-block;
                float: right;
            }

            .btn-success {
                margin-right: 24.75px;
            }
        }

        h3 {
            margin-bottom: 11.25px;

            @include breakpoint(m) {
                margin-bottom: 13.5px;
            }

            color: $light-black;
        }

        p {
            font-size: 18px;
            color: $light-black;
            line-height: 30px;
        }
    }

    .btn {
        margin-top: 11.25px;

        @include breakpoint(m) {
            margin-top: 62.25px;
        }
    }
}

.sharing {
    a {
        text-decoration: none;
    }
    .social-media {
        height: 40.5px;
        width: auto;
        margin-right: 10.5px;
    }
}